<template>
  <div class="hero text-white w-full">
    <div class="container mx-auto p-5 sm:py-20">
      <div class="w-11/12 md:w-3/4 lg:w-1/2">
        <p class="text-5xl font-black">The right way to get a car</p>
        <div class="md:flex mt-10">
          <div class="">
            Monza Motorsports is a boutique-style dealership specializing in premium imports. We offer an advanced online vehicle purchasing experience with integrated financing and contactless home delivery.
          </div>
          <div class="mt-5 md:mt-0 md:ml-10">
            All of our cars come with a 5day/500 kilometers no-hassle exchange option. Since 2012 Monza Motorsports has been the benchmark for innovation in the automotive industry.
          </div>
        </div>
        <button @click="$emit('browse')" class="hover:bg-white hover:text-black border-white text-white border-solid border-2 border-width-2 p-3 rounded-large text-lg font-bold mt-8 w-full md:w-1/2">Browse cars</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {}
}
</script>

<style scoped>
  .hero {
    background: black url('../assets/hero-background.jpg') no-repeat center right;
    background-size: contain;
  }
</style>