<template>
  <Header></Header>
  <Hero @browse="browse()"></Hero>

  <div ref="portfolio" class="container mx-auto p-5">
    <h3 class="my-5 sm:my-20 font-black text-3xl sm:text-5xl text-gray-400 uppercase text-center">Current offers</h3>
    <template v-if="loading">
      <div class="text-center mb-10 text-xl">Loading...</div>
    </template>
    <div v-else class="mb-5 sm:mb-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      <RouterLink v-for="(vehicle, key) in vehicles" :key="`home-vehicle-list-${key}`" :to="{name: 'VehicleDetail', params: {id: vehicle.id}}" class="hover:translate-y-3">
        <VehicleListItem :vehicle="vehicle"></VehicleListItem>
      </RouterLink>
    </div>
  </div>
  <Subscribe></Subscribe>
  <Features></Features>
  <Footer></Footer>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import Header from '@/components/Header.vue'
import Hero from '@/components/Hero.vue'
import VehicleListItem from '@/components/VehicleListItem.vue'
import Subscribe from '@/components/Subscribe.vue'
import Features from '@/components/Features.vue'
import Footer from '@/components/Footer.vue'
import {useHead} from "@vueuse/head"

export default {
  setup () {
    useHead({
      title: 'Monza Motorsports',
    })
  },
  components: {
    Header,
    Hero,
    VehicleListItem,
    Subscribe,
    Features,
    Footer
  },
  created () {
    this.getAllVehicles()
  },
  data () {
    return {
      vehicles: [],
      loading: true
    }
  },
  methods: {
    getAllVehicles: async function () {
      this.loading = true
      let repeat = true
      let start = 0
      do {
        let data = await axios.get(`${process.env.VUE_APP_API_SERVER}/vehicles?_start=${start}&_limit=12`)
        let vehicles = data.data
        if (vehicles.length) {
          this.vehicles = _.concat(this.vehicles, vehicles)
          this.vehicles = _.reverse(this.vehicles)
          this.vehicles = _.sortBy(this.vehicles, function (d) {
            return d.status === "sold" ? 100 : 0
          })
          start += start + vehicles.length-1
          this.loading = false
        } else {
          repeat = false
        }
      } while (repeat);
    },
    browse: function () {
      let element = this.$refs['portfolio']
      let top = element.offsetTop
      window.scrollTo(0, top)
    }
  }
}
</script>