<template>
  <div class="my-20">
    <div class="p-5 container mx-auto flex flex-wrap lg:flex-nowrap">
      <div class="lg:flex-1 w-full">
        <div class="lg:mx-auto lg:w-9/12">
          <p class="w-full text-center lg:text-left lg:w-9/12 mb-5 text-2xl font-bold text-gray-600">Interested in this vehicle?</p>
        </div>

        <div class="w-full mx-auto lg:w-9/12">
          <template v-if="!contactFormSent">
            <form @submit.prevent="sendContactForm">
              <div class="mb-5">
                <input v-model="contactForm.name" required class="w-full border border-gray-300 rounded py-2 px-3" type="text" name="name" placeholder="Your name" />
              </div>
              <div class="mb-5">
                <input v-model="contactForm.email" required class="w-full block border border-gray-300 rounded py-2 px-3" type="email" name="email" placeholder="Email" />
              </div>
              <div class="mb-5">
                <input v-model="contactForm.phone" class="w-full block border border-gray-300 rounded py-2 px-3" type="text" name="phone" placeholder="Phone #, ex: (555) 555-5555" />
              </div>
              <input required class="block bg-red-500 rounded-full mt-5 py-2 px-10 text-white" type="submit" value="Submit" />
            </form>
          </template>
          <template v-else>
            <p class="text-center font-bold text-xl mb-3">We received your message!</p>
            <p class="text-center ">Our manager will contact you as soon as possible.</p>
          </template>
        </div>
      </div>
      <div class="lg:ml-10 lg:flex-1 w-full mt-10 lg:mt-0">
        <div class="mx-auto lg:w-9/12">
          <p class="w-9/12 mb-5 text-2xl font-bold text-gray-600">Contact us directly</p>
        </div>

        <div class="mx-auto lg:w-9/12">

            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/phone.svg" />
              <div class="flex-1 ml-7">1-844-566-6926</div>
            </div>

            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/clock.svg" />
              <div class="flex-1 ml-7">
                <p>Mon-Fri: 10am - 6pm</p>
              </div>
            </div>
            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/email.svg" />
              <div class="flex-1 ml-7">
                <a href="mailto:monzamotors.ca@gmail.com" class="text-yellow-400 hover:text-yellow-500 hover:underline">monzamotors.ca@gmail.com</a>
              </div>
            </div>


            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/location.svg" />
              <div class="flex-1 ml-7">
                <p class="font-bold">Address</p>
                <p class="font-bold">Monza Motorsports</p>
                <p>222 Evans Ave.</p>
                <p>Etobicoke, Ontario</p>
                <p>M8Z 1J8</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ['vehicle'],
  data () {
    return {
      contactFormSent: false,
      contactForm: {
        message: `Interested in ${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`
      }
    }
  },
  methods: {
    sendContactForm: async function () {
      let result = await axios.post(`${process.env.VUE_APP_API_SERVER}/contacts`, {
        name: this.contactForm.name,
        email: this.contactForm.email,
        phone: this.contactForm.phone,
        message: this.contactForm.message,
      })

      if (result.status === 200) {
        this.contactFormSent = true
      } else {
        alert('Something went wrong, please try again')
      }
    }
  }
}
</script>