<template>
  <div class="flex flex-wrap sm:flex-nowrap p-5 sm:px-16 sm:py-5 justify-between bg-black text-white">
    <div>
      <RouterLink :to="{name: 'Home'}">
        <Logo></Logo>
      </RouterLink>
    </div>
    <div class="w-full sm:w-auto self-center">
      <Menu></Menu>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import Menu from '@/components/Menu.vue'

export default {
  components: {
    Logo,
    Menu
  }
}
</script>

<style>
</style>