<template>
  <div class="bg-white">
    <div class="p-5 sm:py-20 container mx-auto flex lg:flex-nowrap flex-wrap text-center">
      <div class="lg:w-1/5">
        <h2 class="text-2xl font-bold mb-4">Extended warranty</h2>
        <p>We offer comprehensive mechanical breakdown protection to cover your vehicle beyond the standard warranty offered by manufacturer</p>
      </div>
      <div class="ml-2 mt-4 sm:mt-0 lg:w-1/5">
        <h2 class="text-2xl font-bold mb-4">Trade-ins</h2>
        <p>We welcome your trade-ins and handle all disbursements associated with your previous auto loans. Rest assured you'd be getting top value for your old car.</p>
      </div>
      <div class="ml-2 mt-4 sm:mt-0 lg:w-1/5">
        <h2 class="text-2xl font-bold mb-4">Reconditioning</h2>
        <p>All our vehicles are meticulously reconditioned to the highest standards using the best supplies and equipment. You're welcome for a detail anytime.</p>
      </div>
      <div class="ml-2 mt-4 sm:mt-0 lg:w-1/5">
        <h2 class="text-2xl font-bold mb-4">Home Delivery</h2>
        <p>You may visit our showroom or arrange for us to bring any vehicle to your home or office for a no hassle & no haggle experience.</p>
      </div>
      <div class="ml-2 mt-4 sm:mt-0 lg:w-1/5">
        <h2 class="text-2xl font-bold mb-4">Financing</h2>
        <p>We work with major banks to offer you the most attractive financing and leasing options in the industry that's fully integrated in the purchase experience.</p>
      </div>
    </div>
  </div>
</template>