<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-black" style="opacity:.8" />
            </TransitionChild>
            <span class="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95">
              <div class="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                <div class="mt-2">

                  <img
                      v-touch:swipe.right="pictureRight"
                      v-touch:swipe.left="pictureLeft" :src="`${SERVER_URL}${vehicle.images[selectedImageModal].formats.large.url}`" class="rounded" />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <TransitionRoot appear :show="isOpenFacetime" as="template">
      <Dialog as="div" @close="closeModalFacetime">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-black" style="opacity:.8" />
            </TransitionChild>
            <span class="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95">
              <div class="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
                <Interested v-if="vehicle" :vehicle="vehicle"></Interested>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="lg:mt-10 container mx-auto flex-wrap lg:flex-nowrap flex p-5">
      <!-- gallery-->
      <div class="lg:w-1/2 w-full text-center">
        <div class="lg:w-11/12">
            <div class="relative">
              <img @click="openModal(0)" class="rounded-md shadow-md" :src="`${SERVER_URL}${vehicle.images[selectedImage].formats.large.url}`" />
              <div v-if="vehicle.status === 'sold'" class="top-2 right-2 inline mx-auto absolute uppercase text-4xl font-black text-yellow-500 bg-black rounded px-2 py-1 text-center">Sold</div>
            </div>
          <div class="grid grid-cols-5 gap-4 mt-4">
            <div v-for="(img, key) in vehicle.images" :key="`vehicle_detail_${key}`">
              <img @click="openModal(key)" :class="{'border-red-500': key === selectedImage}" class="cursor-pointer border-2 shadow-md rounded-md" :src="`${SERVER_URL}${img.formats.small.url}`" />
            </div>
          </div>
        </div>
      </div>
      <!-- specs -->
      <div class="mt-5 lg:mt-0 w-full lg:w-1/2">
        <h1 class="text-3xl font-bold"><span class="text-gray-500">{{ vehicle.year }}</span> {{ vehicle.make }} {{ vehicle.model }}</h1>
        <h2 class="text-2xl font-black text-gray-600 mt-4">${{ vehicle.price }}</h2>

        <div class="pt-10 w-full lg:w-9/12">
          <div v-if="vehicle.bodyStyle" class="lg:mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Body style</div>
            <div class="text-right flex-1">{{ vehicle.bodyStyle.toUpperCase() }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Make</div>
            <div class="text-right flex-1">{{ vehicle.make }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Model</div>
            <div class="text-right flex-1">{{ vehicle.model }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Mileage</div>
            <div class="text-right flex-1">{{ vehicle.mileage }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Transmission</div>
            <div class="text-right flex-1 capitalize">{{ vehicle.transmission }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Engine</div>
            <div class="text-right flex-1">{{ vehicle.engine }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Engine size</div>
            <div class="text-right flex-1">{{ vehicle.engineSize }}</div>
          </div>
          <div v-if="vehicle.drivetrain" class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Drivetrain</div>
            <div class="text-right flex-1">{{ vehicle.drivetrain.toUpperCase() }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Interior color</div>
            <div class="text-right flex-1">{{ vehicle.interiorColor }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Exterior color</div>
            <div class="text-right flex-1">{{ vehicle.exteriorColor }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Doors</div>
            <div class="text-right flex-1">{{ vehicle.doors }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Fuel type</div>
            <div class="text-right flex-1 capitalize">{{ vehicle.fuel }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">City fuel</div>
            <div class="text-right flex-1">{{ vehicle.cityFuel }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Hwy fuel</div>
            <div class="text-right flex-1">{{ vehicle.hwyFuel }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">Stock Number</div>
            <div class="text-right flex-1">{{ vehicle.stock }}</div>
          </div>
          <div class="mr-10 flex w-full">
            <div class="w-3/12 flex-1 font-bold">VIN</div>
            <div class="text-right flex-1">{{ vehicle.vin }}</div>
          </div>
          <div v-if="vehicle.description" class="bg-gray-100 mt-5 p-4 rounded">
            <vue-markdown :source="vehicle.description"></vue-markdown>
          </div>
        </div>
        <div class="mt-3">
          <button @click="openModalFacetime" class="bg-green-500 rounded py-2 px-4">
            <div class="flex items-center text-white">
              <img class="text-white mr-2 w-7 h-7" src="@/assets/video-call.svg" /> <span>Request video call</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Interested from '@/components/Interested.vue'
import VueMarkdown from 'vue-markdown-render'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay
} from '@headlessui/vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    VueMarkdown,
    Interested
  },
  props: ['vehicle'],
  data () {
    return {
      selectedImage: 0,
      selectedImageModal: 0,
      isOpen: false,
      isOpenFacetime: false,
      SERVER_URL: process.env.VUE_APP_API_SERVER
    }
  },
  methods: {
    selectImage: function (index) {
      this.selectedImage = index
    },
    closeModal: function() {
      this.isOpen = false
    },
    closeModalFacetime: function() {
      this.isOpenFacetime = false
    },
    openModal: function(imageIndex) {
      this.selectedImageModal = imageIndex
      this.isOpen = true
    },
    openModalFacetime: function() {
      this.isOpenFacetime = true
    },
    handleKeydown (e) {
      switch (e.keyCode) {
        case 37:
          this.pictureLeft();
          break;
        case 39:
          this.pictureRight();
          break;
      }
    },
    pictureLeft: function() {
      if (!this.isOpen || !this.vehicle.images.length) return
      if (this.selectedImageModal === 0) this.selectedImageModal = this.vehicle.images.length-1
      this.selectedImageModal--
    },
    pictureRight: function() {
      if (!this.isOpen || !this.vehicle.images.length) return
      if (this.selectedImageModal === this.vehicle.images.length-1) this.selectedImageModal = 0
      this.selectedImageModal++
    },
    onSwiper: function (swiper) {
      console.log(swiper);
    }
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.handleKeydown);
  }
}
</script>