<template>
  <ul class="flex flex-wrap w-full sm:flex-nowrap items-center justify-center overflow-hidden">
<!--    <li class="mt-5 sm:mt-0 w-full text-center sm:text-left sm:w-auto">Inventory</li>-->
<!--    <li class="mt-5 sm:mt-0 w-full text-center sm:text-left sm:w-auto">Financing</li>-->
    <RouterLink :to="{name: 'Contact'}">
      <li class="mt-5 sm:mt-0 w-full text-center sm:text-left sm:w-auto">Contact</li>
    </RouterLink>
    <li class="mt-5 sm:mt-0 w-full text-center sm:text-left sm:w-16 flex mx-auto justify-center">
      <a href="https://instagram.com/monzamotorsports" target="_blank"><img class="w-4 h-4 ml-4 self-center" src="@/assets/menu-instagram.svg" /></a>
    </li>
  </ul>
</template>

<style scoped>
ul li {
  @apply sm:ml-8;
}
</style>