<template>
  <div class="flex flex-col h-screen justify-between">
    <div>
      <Header></Header>
      <div class="lg:bg-black">
        <div class="container mx-auto p-5 lg:flex justify-center">
          <div class="lg:w-5/12"></div>
          <div class="lg:w-5/12">
            <h3 class="text-center lg:text-left lg:mb-10 mt-5 lg:mt-32 font-black text-3xl sm:text-5xl text-gray-400 uppercase">Contact us</h3>
          </div>
        </div>
      </div>
      <div>
        <div class="container p-5 mx-auto lg:flex justify-center">
          <div class="bg-white shadow-xl lg:-mt-48 mb-10 lg:w-4/12 p-10 rounded-lg">
            <template v-if="!contactFormSent">
              <p class="text-center">There are many ways to reach us.</p>
              <p class="text-center">Let us know your question or concern.</p>
              <form @submit.prevent="sendContactForm">
              <div class="mt-10">
                <div class="mb-5">
                  <label class="block mb-3">Name</label>
                  <input required v-model="contactForm.name" class="w-full border border-gray-300 rounded py-2 px-3" type="text" name="name" placeholder="Your name" />
                </div>
                <div class="mb-5">
                  <label class="block mb-3">Email</label>
                  <input required v-model="contactForm.email" class="w-full block border border-gray-300 rounded py-2 px-3" type="email" name="email" placeholder="Email" />
                </div>
                <div class="mb-5">
                  <label class="block mb-3">Phone</label>
                  <input required v-model="contactForm.phone" class="w-full block border border-gray-300 rounded py-2 px-3" type="text" name="phone" placeholder="Phone #, ex: (555) 555-5555" />
                </div>

                <div class="mb-5">
                  <label class="block mb-3">Message</label>
                  <textarea v-model="contactForm.message" class="h-32 w-full block border border-gray-300 rounded py-2 px-3" placeholder="How can we help you?"></textarea>
                </div>

                <p class="text-gray-400 text-sm">By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information</p>

                <input class="block bg-red-500 rounded-full mt-5 mx-auto py-2 px-10 text-white" type="submit" value="Submit" />
              </div>
            </form>
            </template>
            <template v-else>
              <img class="w-20 h-20 mx-auto mb-5" src="@/assets/check.svg" />
              <p class="text-center font-bold text-xl mb-3">We received your message!</p>
              <p class="text-center ">Our manager will contact you as soon as possible.</p>
            </template>
          </div>

          <div class="mx-auto lg:mx-0 lg:pl-16 pt-10 lg:w-5/12">
            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/phone.svg" />
              <div class="flex-1 ml-7">1-844-566-6926</div>
            </div>

            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/clock.svg" />
              <div class="flex-1 ml-7">
                <p>Mon-Fri: 10am - 6pm</p>
              </div>
            </div>
            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/email.svg" />
              <div class="flex-1 ml-7">
                <a href="mailto:monzamotors.ca@gmail.com" class="text-yellow-400 hover:text-yellow-500 hover:underline">monzamotors.ca@gmail.com</a>
              </div>
            </div>


            <div class="flex mb-5">
              <img class="w-5 h-5" src="@/assets/location.svg" />
              <div class="flex-1 ml-7">
                <p class="font-bold">Address</p>
                <p class="font-bold">Monza Motorsports</p>
                <p>222 Evans Ave.</p>
                <p>Etobicoke, Ontario</p>
                <p>M8Z 1J8</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import axios from 'axios'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {useHead} from "@vueuse/head";
export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      contactFormSent: false,
      contactForm: {}
    }
  },
  setup () {
    useHead({
      title: 'Contact Us - Monza Motorsports',
    })
  },
  methods: {
    sendContactForm: async function () {
      let result = await axios.post(`${process.env.VUE_APP_API_SERVER}/contacts`, {
        name: this.contactForm.name,
        email: this.contactForm.email,
        phone: this.contactForm.phone,
        message: this.contactForm.message,
      })

      if (result.status === 200) {
        this.contactFormSent = true
      } else {
        alert('Something went wrong, please try again')
      }
    }
  }
}
</script>