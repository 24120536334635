import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import VehicleDetail from '../views/VehicleDetail.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detail/:id',
    name: 'VehicleDetail',
    component: VehicleDetail
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return {left: 0, top: 0}
  }
})

export default router
