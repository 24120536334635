<template>
  <Header></Header>
  <VehicleItem v-if="vehicle" :vehicle="vehicle"></VehicleItem>
  <Interested v-if="vehicle" :vehicle="vehicle"></Interested>
  <Features></Features>
  <Footer></Footer>
</template>

<script>

import axios from 'axios'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import VehicleItem from '@/components/VehicleItem.vue'
import Features from '@/components/Features.vue'
import Interested from '@/components/Interested.vue'
import { useHead } from '@vueuse/head'

export default {
  setup () {
    useHead({
      title: 'Monza Motorsports - Vehicle details',
    })
  },
  components: {
    Header,
    Footer,
    VehicleItem,
    Interested,
    Features
  },
  data: function () {
    return {
      vehicle: null
    }
  },
  created () {
    let id = this.$route.params.id
    this.getVehicleDetails(id)
  },
  methods: {
    getVehicleDetails: async function (id) {
      let data = await axios.get(`${process.env.VUE_APP_API_SERVER}/vehicles/${id}`)
      this.vehicle = data.data
    }
  }
}
</script>