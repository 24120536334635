<template>
  <div class="mt-10 rounded-lg shadow-lg overflow-hidden">
    <div class="relative">
      <img class="rounded-t-lg" :src="`${SERVER_URL}${vehicle.images[0].formats.small.url}`" />
      <div v-if="vehicle.status === 'sold'" class="top-2 right-2 px-2 py-1 rounded mx-auto absolute font-black text-black shadow-lg uppercase text-center bg-yellow-400 inline">Sold</div>
    </div>
    <div class="bg-white p-5">
      <p class="text-xl">
        <span class="text-gray-500 mr-2">{{ vehicle.year }}</span>
        <span class="font-medium">{{ vehicle.make }} {{ vehicle.model }}</span>
      </p>
      <p class="mt-4 text-gray-400">
        {{ vehicle.mileage }} · {{ vehicle.transmission }}<template v-if="vehicle.fuelType"> · {{ vehicle.fuelType }}</template>
      </p>
      <p class="mt-4 font-bold text-2xl">${{vehicle.price}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['vehicle'],
  data () {
    return {
      SERVER_URL: process.env.VUE_APP_API_SERVER
    }
  }
}
</script>