<template>
  <div class="bg-black text-white">
    <div class="container lg:flex mx-auto p-5 sm:py-20">
      <template v-if="!newsletterEmailSent">
        <div class="">
          <h3 class="text-3xl font-bold mb-5">Haven't found a suitable vehicle?</h3>
          <p>Sign up for our newsletter and be the first to know when we publish new vehicle offers.</p>
        </div>

          <div class="self-center lg:w-2/3 lg:ml-40 lg:mt-0 mt-10">
            <form class="flex" @submit.prevent="sendContactForm">
              <input required v-model="newsletterEmail" type="email" class="self-center rounded-large outline-none border-white border bg-black p-4 w-3/4" placeholder="Enter your email"/>
              <div class="self-center ml-3">
                <button class="p-4 rounded-large bg-white text-black overflow-hidden">Subscribe</button>
              </div>

            </form>
          </div>

      </template>
      <template v-else>
        <div class="w-full">
          <p class="text-center mb-5 text-2xl font-bold">Thank you for registering</p>
          <p class="text-center">We will send you an email as soon as new vehicle offers are published.</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data () {
    return {
      newsletterEmail: null,
      newsletterEmailSent: false
    }
  },
  methods: {
    sendContactForm: async function () {
      let result = await axios.post(`${process.env.VUE_APP_API_SERVER}/newsletters`, {
        email: this.newsletterEmail
      })

      if (result.status === 200) {
        this.newsletterEmailSent = true
      } else {
        alert('Something went wrong, please try again')
      }
    }
  }
}
</script>